import {graphql, navigate} from 'gatsby'
import {ResourceTemplatePageDataQuery} from 'gatsby-graphql-types-generated'
import {useEffect} from 'react'

import ResourcePageTemplate from 'src/components/ResourcePageTemplate'

export const query = graphql`
  query ResourceTemplatePageData($id: String) {
    contentfulResourcePage(id: {eq: $id}) {
      ...StaticResourcePage
    }
  }
`

const ResourcePageContext = ({data}: {data: ResourceTemplatePageDataQuery}) => {
  useEffect(() => {
    if (
      data.contentfulResourcePage?.permalink.split('/')[1] ===
        'financial-aid-help' &&
      typeof window !== 'undefined'
    ) {
      const script = document.createElement('script')
      script.src = 'https://webbot.mainstay.com/static/js/webchat.js'
      script.async = true
      document.body.appendChild(script)

      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = 'https://webbot.mainstay.com/static/css/webchat.css'
      document.head.appendChild(link)
      ;(window as any).admitHubBot = {
        botToken: '199f519170fc492b9',
        collegeId: 'getSchooled',
      }

      return () => {
        document.body.removeChild(script)
        document.head.removeChild(link)
      }
    }
  }, [data.contentfulResourcePage])
  if (!data.contentfulResourcePage) {
    navigate('404')
    return null
  }

  return <ResourcePageTemplate resourcePage={data.contentfulResourcePage} />
}

export default ResourcePageContext
